<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-2">Invoice</div>

    <BTabs
      class="mt-2"
      content-class="mt-1"
      pills
      active-nav-item-class="rounded-pill"
      v-model="tabIndex"
    >
      <BTab
        title="Aktif"
        active
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <Active />
      </BTab>
      <BTab
        title="Riwayat"
        :active="$route.query.tab === 'history'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <History />
      </BTab>
    </BTabs>
  </BCard>
</template>

<script>
import Active from './status/Active.vue'
import History from './status/History.vue'

export default {
  components: {
    Active,
    History,
  },
  data() {
    return {
      tabIndex: 0,
      tabs: ['active', 'history'],
    }
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })

    const tab = this.tabs[0]
    if (this.$route.query.tab !== tab) this.$router.replace({ query: { tab } })
  },
  watch: {
    tabIndex(newTab, oldtab) {
      const tab = this.tabs[newTab]
      if (this.$route.query.tab !== tab) this.$router.replace({ query: { tab } })
    },
  },
}
</script>
